.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #2196F3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.customize_layout-modal-left{
  border: 1px solid #cccccc;
  padding: 0 15px;
  margin-bottom: 15px
}
.customize_layout-modal-left .customize_layout-modal-left-header{
  text-align: center;
  margin: 0
}
.customize_layout-modal-left-body{
  padding-top: 15px;
}
.customize_layout-modal-left-body p{
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  line-height: 24px
}
.customize_layout-modal-left-body:last-child{
  padding-bottom: 10px
}
.customize-layout-section .customize-layout-header-section .customize-layout-profile-section .profile-image img{
  width: 40px;
  height: 40px;
  border-radius: 30px
}
.customize-layout-section .profile-name h3{
  margin: 10px 0 !important;
  font-size: 20px;
}
.customize-layout-section .profile-title h6{
  margin-bottom: 0 !important;
  font-size: 12px
}
.customize-layout-section .close-section a{
  color: #ffffff;
}
.customize-layout-box-bar.panel{
  border-radius: 0 !important;
  box-shadow: inherit !important;
}
.customize-layout-section.panel-header{

}
@media only screen and (min-width: 768px){
  .customize-layout-box-bar.panel .customize-layout-header-section .custom-bar-section{
    display: flex;
    /*align-items: center;*/
    /*justify-content: space-between;*/
  }
  .customize-layout-box-bar.panel .head-sub-section,.customize-layout-box-bar.panel .plugin_messenger,.customize-layout-box-bar.panel .button-section{
    align-self: center
  }
}
.customize-layout-box-bar.panel .customize-layout-header-section .custom-bar-section{
  text-align: center;
}
.customize-layout-box-center{
  border-radius: 0 !important;
  box-shadow: inherit !important;
  text-align: center;
  margin: 0 auto;
  float: none;
}
.customize-layout-image-section{
  margin: 20px auto;
  display: inline-block;
  float: none;
}

.customize-layout-box-center .customize-layout-section.panel-header{
  padding-top: 0 !important;
}
.customize-layout-box-center .customize-layout-profile-section {
  padding: 25px 15px 20px;
}
.panel.customize-layout-box-center{
  padding-top: 22px;
}
/*.customize-layout-box-center .close-section{
position: absolute;
right: 2px;
top: 2px;
}*/
.custom-file-upload input[type="file"] {
  display: none;
}
.custom-file-upload {
  cursor: pointer;
}
.mtb-5{
  margin: 5px 0;
}
.conversation_tab .tab-content{
  border-top: medium none; 
}


label.conversation-custom-radio {
  border: 1px solid #d5d5d5;
  padding: 10px;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  background-color: #f7f7f7
}
label.conversation-custom-radio h3{
  margin: 0 0 10px!important;
  text-align: center;
  font-weight: bold !important;
}
label.conversation-custom-radio .iradio_minimal-grey{
  position: absolute;
  top: 0 !important;
  margin-right: 0;
  right: 0px;
}
label.conversation-custom-radio .iradio_minimal-grey{
  background: none 
}
label.conversation-custom-radio .iradio_minimal-grey.checked{
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #319BD5;
}
label.conversation-custom-radio .iradio_minimal-grey.checked:before{
  position: absolute;
  content: "\e013";
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  left: 21px;
  top: 3px;
}
label.conversation-custom-radio .iradio_minimal-grey.checked > h3{
  color: #319BD5;
}
.conversation_tab .nav-pills li a {
  border: 1px solid #d8d8d8;
  margin: 0;
  background-color: transparent;
  color: #333333;
  padding: 5px 20px;
  text-align: center;
}
.conversation_tab .nav-pills li {
  padding: 0 5px;
  margin: 0
}
.conversation_tab .nav-pills li:hover a, .conversation_tab .nav-pills li.active a{
  background-color: #d8d8d8;
  color: #333333;
}
.conversation_tab .tab-content.clearfix {
  border-top: medium none;
  margin-top: 30px;
  display: inline-block;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #eee;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #333333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-checkbox .icheckbox_minimal-grey {
  display: none;
}
.main-content .page-content .panel.customize-layout-box-center{
  background-color: #f4f4f4;
  color: #ffffff;
  padding: 15px 
}
.main-content .page-content .panel.customize-layout-box-bar{
  background-color: #f4f4f4;
  box-shadow: 0px -2px 18px 0px rgba(0, 0, 0, 0.47) !important;
}
.plugin_messenger{
  margin: 0 auto;
  float: none;
  display: inline-block;
  width: auto;
  text-align: center;
  padding: 8px 28px;
}
.plugin_messenger img{
  margin:  0 auto
}
.plugin_messenger_sub_image{
  margin-top: 8px
}
.plugin_messenger_sub_image img{
  width: 30px;
  float: none;
  margin-right: 10px;
  display: inline-block;
}
.plugin_messenger_sub_image a {
  margin: 5px 5px;
  display: inline-block;
  color: #ffffff;
}
.checkbox_messenger_plugin{
  font-size: 15px;
  padding-left: 30px;
  float: none;
  display: inline-block;
  text-align: center;
}
.custom-checkbox.checkbox_messenger_plugin .checkmark{
  width: 20px;
  height: 20px
}
.custom-checkbox.checkbox_messenger_plugin .checkmark:after{
  left: 7px;
  top: 4px;
}
.checkbox_messenger_image{
  float: none;
  display: inline-block;
}
.checkbox_messenger_box{
  display: inline-block;
  width: 100%;
  float: none;
  text-align: center;
  margin: 0 auto;
}
#element_section .sp-preview-inner{
  border: 2px solid #EDEDED;
}
.play-button{.play-button {
  position: absolute;
  top: 52%;
  left: 45%;
  width: auto;
  height: auto;
  transform: translate(52%,45%);
  }}
  .play-button span{position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;font-size:50px;width:40px;height:40px;color:#ff0000;text-align:center;line-height:40px;opacity:.8;-webkit-transition:all 0.4s ease;-o-transition:all 0.4s ease;transition:all 0.4s ease}
  .play-button span{opacity:1}
  .video_link{
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .main-content .page-content .transparent-border{
    border: 1px solid transparent;
  }
  .bar .panel-body{
    display: inline-block;
  }
  .bar-section{
    border: 1px solid #cccccc;
  }
  .main-content .page-content .bar-hide  .panel .customize-layout-section.panel-header{
    padding: 0 15px;
  }
  .main-content .page-content .bar-hide .customize-layout-profile-section{
    background-color: #ffffff;
    padding: 10px
  }
  .panel.customize-layout-box-bar .close-box{
    position: absolute;
    right: 0px;
    top: 2px;
    text-align: right
  }
  .panel.customize-layout-box-bar .plugin_messenger{
    float: left;
    text-align: left;
  }
  .panel.customize-layout-box-bar .plugin_messenger img{
    margin: 0
  }
  .panel.customize-layout-box-bar .plugin_messenger .checkbox_messenger_box{
    float: left;
    text-align: left; 
    margin-bottom: 10px
  }
  .bottom-header{
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    left: 0;
  }
  .main-content .page-content .bottom-header .panel{
    margin-bottom: 0px;
  }
  .bottom-header + .content-section{
    padding-bottom: 200px;
  }
  .customize_layout-modal-left-body.barContentHide:nth-child(4n + 1) {
    clear: both;
  }
  .main-content .page-content .centre-modal .panel.customize-layout-box-center{
    border-radius: 10px !important;
    box-shadow: 0px -2px 18px 0px rgba(0, 0, 0, 0.47) !important

  }
  .iframeWidth iframe{
    width: 100%;
    height :auto;
  }
  .main-content .page-content .slide-in .panel.customize-layout-box-center{
    border-radius: 10px !important;
    box-shadow: 0px -2px 18px 0px rgba(0, 0, 0, 0.47) !important
  }
  .radio label.slide-in-position-label-radio{
    float: left;
    width: 50%;
    display: inline-block;
    padding-left: 19px;
    font-size: 14px;
  }
  .radio label.slide-in-position-label-radio span{
    left: 0
  }
  #displayTab_table th{
    border-bottom: medium none;
  }
  #displayTab_table .form-control{
    width: 100% !important
  }
  #displayTabExclude_table th{
    border-bottom: medium none;
  }
  #displayTabExclude_table .form-control{
    width: 100% !important
  }
  p.convertmate_layout_notes {
    color: red;
    font-size: 10px;
  }
  hr.border-hr{
    float: left;
    width: 100%;
  }
/*.form-group-profile-section .col-md-4.form-group:nth-child(4){
clear: both
}*/
.subBtnPosition{
  margin-left: auto !important;
  margin-right: 0;
}
.subAllignToolCenter{
  text-align: center !important;
}
.subAllignToolRight{
  text-align: right !important;
}
.buttonPosition{
  margin-left: auto !important;
  margin-right: 0;
}
.main-content .page-content .customize-layout-box-bar{
  margin-bottom: 0
}
.main-content .page-content .customize-layout-box-center{
  margin-bottom: 0
}
.character-count{
  position: absolute;
  right: 15px;
  top: 7px;
  font-size: 14px;
  color: #ccc;
  background-color: #ffffff;
  height: 25px;
  width: 25px;
  text-align: center;
}
.customize-layout-brandImage-section{
  float: right;
  width: 20%;                                                                                                                                ;
}
.brand_image{
  opacity: 0.45;                                                                                                                             ;
}
.brandPosition{
  position: absolute;
  top: -32px;
  right: 17px;
}
.customize-layout-brandImage-modalSlideIn-section{
  float: none;
  width: 155px;
  display: inline-block;
}
.cke_textarea_inline.cke_editable.cke_editable_inline.cke_contents_ltr.cke_show_borders {
  border-bottom: 1px solid #ededed;
  padding-right: 25PX;
  width: 100%;
  min-height: 25px;
}
.custom-text-center p{
  text-align: center;
}
.code_disabled a {
  cursor: not-allowed !important;
}
/* General styles for the modal */

/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.md-perspective,
.md-perspective body {
  height: 100%;
  overflow: hidden;
}

.md-perspective body  {
  background: #222;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}


/*.md-modal {
position: fixed;
top: 50%;
left: 50%;
width: 50%;
max-width: 630px;
min-width: 320px;
height: auto;
z-index: 2000;
visibility: hidden;
-webkit-backface-visibility: hidden;
-moz-backface-visibility: hidden;
backface-visibility: hidden;
-webkit-transform: translateX(-50%) translateY(-50%);
-moz-transform: translateX(-50%) translateY(-50%);
-ms-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);
}

.md-show {
visibility: visible;
}

.md-overlay {
position: fixed;
width: 100%;
height: 100%;
visibility: hidden;
top: 0;
left: 0;
z-index: 1000;
opacity: 0;
background: rgba(143,27,15,0.8);
-webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
transition: all 0.3s;
}

.md-show ~ .md-overlay {
opacity: 1;
visibility: visible;
}*/



/* Individual modal styles with animations/transitions */

/* Effect 1: Fade in and scale up */
.fade-in-scale {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.fade-in-scale {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 2: Slide from the right */
.slide-in-right {
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.md-show.slide-in-right {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

/* Effect 3: Slide from the bottom */
.slide-in-bottom {
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.slide-in-bottom {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 4: Newspaper */
.newspaper {
  -webkit-transform: scale(0) rotate(720deg);
  -moz-transform: scale(0) rotate(720deg);
  -ms-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.md-show.newspaper ~ .md-overlay,
.newspaper {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.md-show.newspaper {
  -webkit-transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

/* Effect 5: fall */
.fall.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.fall {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(600px) rotateX(20deg); 
  -moz-transform: translateZ(600px) rotateX(20deg); 
  -ms-transform: translateZ(600px) rotateX(20deg); 
  transform: translateZ(600px) rotateX(20deg); 
  opacity: 0;
}

.md-show.fall {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translateZ(0px) rotateX(0deg);
  -moz-transform: translateZ(0px) rotateX(0deg);
  -ms-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg); 
  opacity: 1;
}

/* Effect 6: side fall */
.slide-fall-in.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.slide-fall-in {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg); 
  -moz-transform: translate(30%) translateZ(600px) rotate(10deg);
  -ms-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg); 
  opacity: 0;
}

.md-show.slide-fall-in {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translate(0%) translateZ(0) rotate(0deg);
  -moz-transform: translate(0%) translateZ(0) rotate(0deg);
  -ms-transform: translate(0%) translateZ(0) rotate(0deg);
  transform: translate(0%) translateZ(0) rotate(0deg);
  opacity: 1;
}

/* Effect 7:  slide and stick to top */
.slide-in-top{
  top: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slide-in-top {
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  opacity: 0;
}

.md-show.slide-in-top {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  border-radius: 0 0 3px 3px;
  opacity: 1;
}

/* Effect 8: 3D flip horizontal */
.threed-flip-horizontal.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.threed-flip-horizontal {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-70deg);
  -moz-transform: rotateY(-70deg);
  -ms-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.md-show.threed-flip-horizontal {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

/* Effect 9: 3D flip vertical */
.threed-flip-vertical.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.threed-flip-vertical {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-70deg);
  -moz-transform: rotateX(-70deg);
  -ms-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.md-show.threed-flip-vertical {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 10: 3D sign */
.threed-sign.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.threed-sign {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-60deg);
  -moz-transform: rotateX(-60deg);
  -ms-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.threed-sign {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 11: Super scaled */
.super-scaled {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.super-scaled {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 12:  Just me */
.just-me {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.just-me ~ .md-overlay {
  background: #e74c3c;
} 

.just-me h3,
.just-me {
  background: transparent;
}

.md-show.just-me {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 13: 3D slit */
.threed-slit.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.threed-slit {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  -moz-transform: translateZ(-3000px) rotateY(90deg);
  -ms-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0;
}

.md-show.threed-slit {
  -webkit-animation: slit .7s forwards ease-out;
  -moz-animation: slit .7s forwards ease-out;
  animation: slit .7s forwards ease-out;
}

@-webkit-keyframes slit {
  50% { -webkit-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -webkit-animation-timing-function: ease-out;}
  100% { -webkit-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@-moz-keyframes slit {
  50% { -moz-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -moz-animation-timing-function: ease-out;}
  100% { -moz-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@keyframes slit {
  50% { transform: translateZ(-250px) rotateY(89deg); opacity: 1; animation-timing-function: ease-in;}
  100% { transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

/* Effect 14:  3D Rotate from bottom */
.threed-rotate-bottom.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.threed-rotate-bottom {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateY(100%) rotateX(90deg);
  -moz-transform: translateY(100%) rotateX(90deg);
  -ms-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.md-show.threed-rotate-bottom {
  -webkit-transform: translateY(0%) rotateX(0deg);
  -moz-transform: translateY(0%) rotateX(0deg);
  -ms-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

/* Effect 15:  3D Rotate in from left */
.threed-rotate-in-left.md-modal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.threed-rotate-in-left {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -moz-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.threed-rotate-in-left {
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

/* Effect 16:  Blur */
.md-show.fade-in-blur ~ .md-overlay {
  background: rgba(180,46,32,0.5);
}

.md-show.fade-in-blur ~ .container {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
}

.fade-in-blur {
  -webkit-transform: translateY(-5%);
  -moz-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
  opacity: 0;
}

.md-show.fade-in-blur ~ .container,
.fade-in-blur {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.fade-in-blur {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 17:  Slide in from bottom with perspective on container */
.md-show.slide-in-bottom-perspective ~ .container {
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
} 

.md-show.slide-in-bottom-perspective ~ .container,
.md-show.slide-in-bottom-perspective ~ .md-overlay  {
  -webkit-transform: rotateX(-2deg);
  -moz-transform: rotateX(-2deg);
  -ms-transform: rotateX(-2deg);
  transform: rotateX(-2deg);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.slide-in-bottom-perspective {
  opacity: 0;
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
}

.md-show.slide-in-bottom-perspective {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.3s 0.2s;
  -moz-transition: all 0.3s 0.2s;
  transition: all 0.3s 0.2s;
}

/* Effect 18:  Slide from right with perspective on container */
.md-show.slide-in-right-perspective ~ .container {
  height: 100%;
  overflow: hidden;
}

.md-show.slide-in-right-perspective ~ .md-overlay {
  background: rgba(143,27,15,0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.md-show.slide-in-right-perspective ~ .container,
.md-show.slide-in-right-perspective ~ .md-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 0% 50%;
  -moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.5s forwards ease-in;
}

@-webkit-keyframes rotateRightSideFirst {
  50% { -webkit-transform: translateZ(-50px) rotateY(5deg); -webkit-animation-timing-function: ease-out; }
  100% { -webkit-transform: translateZ(-200px); }
}

@-moz-keyframes rotateRightSideFirst {
  50% { -moz-transform: translateZ(-50px) rotateY(5deg); -moz-animation-timing-function: ease-out; }
  100% { -moz-transform: translateZ(-200px); }
}

@keyframes rotateRightSideFirst {
  50% { transform: translateZ(-50px) rotateY(5deg); animation-timing-function: ease-out; }
  100% { transform: translateZ(-200px); }
}

.slide-in-right-perspective {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.md-show.slide-in-right-perspective {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 0.5s 0.1s;
  -moz-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}

/* Effect 19:  Slip in from the top with perspective on container */
.md-show.slide-in-bottom-perspective ~ .container {
  height: 100%;
  overflow: hidden;
}

.md-show.slide-in-bottom-perspective ~ .md-overlay {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.md-show.slide-in-bottom-perspective ~ .container,
.md-show.slide-in-bottom-perspective ~ .md-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 50% 100%;
  -webkit-animation: OpenTop 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 50% 100%;
  -moz-animation: OpenTop 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 50% 100%;
  animation: OpenTop 0.5s forwards ease-in;
}

@-webkit-keyframes OpenTop {
  50% { 
    -webkit-transform: rotateX(10deg); 
    -webkit-animation-timing-function: ease-out; 
  }
}

@-moz-keyframes OpenTop {
  50% { 
    -moz-transform: rotateX(10deg); 
    -moz-animation-timing-function: ease-out; 
  }
}

@keyframes OpenTop {
  50% { 
    transform: rotateX(10deg); 
    animation-timing-function: ease-out; 
  }
}

.slide-in-bottom-perspective {
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  opacity: 0;
}

.md-show.slide-in-bottom-perspective {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.5s 0.1s;
  -moz-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}

@media screen and (max-width: 32em) {
  body { font-size: 75%; }
}

/*fullpage*/
/*.overlayNew {
position: fixed;
width: 100%;
height: 100%;
top: 0;
left: 0;
bottom: 0;
right: 0;
z-index: 11111111;
}*/
.overlay-hugeinc {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, visibility 0s 0.5s;
}
.overlay-hugeinc.open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.overlay-corner {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px) translateX(50px);
  transform: translateY(50px) translateX(50px);
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, transform 0.5s, visibility 0s 0.5s;
}
.overlay-corner.open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
}

.overlay-slidedown {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.4s ease-in-out, visibility 0s 0.4s;
  transition: transform 0.4s ease-in-out, visibility 0s 0.4s;
}

.overlay-slidedown.open {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
}

.overlay-scale {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.overlay-scale.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);  
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
}

.overlay-door {
  visibility: hidden;
  width: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: width 0.5s 0.3s, visibility 0s 0.8s;
  transition: width 0.5s 0.3s, visibility 0s 0.8s;
  position: relative !important;
}
.overlay-door.open {
  visibility: visible;
  width: 100%;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}

.overlay-contentpush {
  background: transparent;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.5s, visibility 0s 0.5s;
  transition: transform 0.5s, visibility 0s 0.5s;
}

.overlay-contentpush.open {
  visibility: visible;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.overlay-contentscale {
  visibility: hidden;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.5s, visibility 0s 0.5s;
  transition: transform 0.5s, visibility 0s 0.5s;
}

.overlay-contentscale.open {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}
.overlay-cornershape {
  background: transparent;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.5s;
  transition: visibility 0s 0.5s;
}

.overlay-cornershape.open {
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}

.overlay-boxes {
  background: transparent;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.8s;
  transition: visibility 0s 0.8s;
}
.overlay-boxes.open {
  visibility: visible;
  -webkit-transition: none;
  transition: none;
}

.overlay-simplegenie {
  visibility: hidden;
  -webkit-transform: translateY(60%) scale(0);
  transform: translateY(60%) scale(0);
  -webkit-transition: -webkit-transform 0.4s, visibility 0s 0.4s;
  transition: transform 0.4s, visibility 0s 0.4s;
}
.overlay-simplegenie.open {
  visibility: visible;
  -webkit-transform: translateY(0%) scale(1);
  transform: translateY(0%) scale(1);
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}
.overlay-genie {
  background: transparent;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0.2s, visibility 0s 0.6s;
  transition: opacity 0.3s 0.2s, visibility 0s 0.6s;
}
.overlay-genie.open {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
/*.overlay .fullPageAnimation {
text-align: center;
position: relative;
top: 50%;
height: 60%;
font-size: 54px;
-webkit-transform: translateY(-50%);
transform: translateY(-50%);
}*/
.overlay-hugeinc .fullPageAnimation {
  -webkit-perspective: 1200px;
  perspective: 1200px;
  opacity: 0.4;
  -webkit-transform: translateY(-25%) rotateX(35deg);
  transform: translateY(-25%) rotateX(35deg);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}
.overlay-hugeinc.open .fullPageAnimation{
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.overlay-door .fullPageAnimation {

  opacity: 0;
  -webkit-transition: opacity 0.3s 0.5s;
  transition: opacity 0.3s 0.5s;

}

.overlay-door.open .fullPageAnimation {
  opacity: 1;
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;

}

/* custom code box design */
.from-group.custom-code {
  border: 1px solid #d8d8d8;
  padding: 15px;
  background-color: #f9f8f8;
}
.from-group.custom-code code{
  background-color: transparent;
  word-break: break-all;
  white-space: inherit
}
.code-footer{
  padding: 10px 15px
}
.fullpage-layout-height,.fullpage-layout-height .main-content ,.fullpage-layout-height .page-content,.fullpage-layout-height .full-page{
  height: 100%;
}
.fullpage-layout-height .main-content{
  height: 100%;
  overflow-y: auto;
}
.fullpage-layout-height .main-content .page-content .panel.customize-layout-box-center {
  display: table;
  width: 100%;
  height: 100%;
}
.fullpage-layout-height .panel-header-fullpage {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.fullpage-layout-height .main-content .page-content .panel.customize-layout-box-center{
  background-color: transparent;
  color: #333333
}
.modal-backdrop{
  background-color: rgba(0, 0, 0, 0.80)
}
.modal.main-content .page-content .panel.customize-layout-box-center{
  background-color: transparent;
}
.modal .customize-layout-brandImage-modalSlideIn-section{
  display: block;
  margin: 0 auto;
  text-align: center;
}
.btn{
  text-transform: uppercase;
}
.btn.layout-btn{
  text-transform: inherit;
}
.bottom-left.modal-dialog {
    position:absolute;
    bottom:0px;
    left:0px;
    margin:0px;
    max-height: 100%
}
.bottom-right.modal-dialog {
    position:absolute;
    bottom:0px;
    right:0px;
    margin:0px;
    max-height: 100%
}
.top-left.modal-dialog {
    position:absolute;
    top:0px;
    left:0px;
    margin:0px;
}
.top-right.modal-dialog {
    position:absolute;
    top:0px;
    right:0px;
    margin:0px;
}
.custom-modal-dialog-centered{
  min-height: calc(100% - 60px);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.custom-code textarea.form-control.code-copy-textarea{
  border-bottom: medium none !important;
  background-image: none;
  word-spacing: normal;
  word-break: break-all;
  font-size: 15px;
  color: #333333;
}
.custom-modal-brand .modal-content {
    background-color: transparent;
    border: medium none;
}
.custom-modal-brand.main-content .page-content .centre-modal .panel.customize-layout-box-center{
    border-radius: 0px !important;
    box-shadow: none !important;
}
.custom-modal-brand.main-content .page-content .centre-modal {
    border-radius: 10px !important;
    box-shadow: 0px -2px 18px 0px rgba(0, 0, 0, 0.47) !important;
}
.custom-slide-modal.modal.show .modal-dialog,.modal.in .modal-dialog{
  transform: translate(0,0) !important;
  -webkit-transform: translate(0,0) !important;
}
.custom-slide-modal.top-left{
  /*-webkit-transform: translate3d(-25%, -25%, 0);
  transform: translate3d(-25%, -25%, 0);*/
  width: 500px;
  position: fixed;
  left: -500px;
  top:0;
}
.custom-slide-modal.bottom-left{
  width: 500px;
  position: fixed;
  left: -500px;
  bottom:0;
}
.custom-slide-modal.top-right{
  width: 500px;
  position: fixed;
  right: -500px;
  top:0;
}
.custom-slide-modal.bottom-right{
  width: 500px;
  position: fixed;
  right: -500px;
  bottom:0;
}

/*.custom-slide-modal.modal.fade:not(.show) .modal-dialog.top-left,.modal.fade:not(.in) .modal-dialog.top-left{
  -webkit-transform: translate3d(-25%, -25%, 0);
  transform: translate3d(-25%, -25%, 0);
}
.custom-slide-modal.modal.fade:not(.show) .modal-dialog.top-right {
  -webkit-transform: translate3d(25%, -25%, 0);
  transform: translate3d(25%, -25%, 0);
}
.custom-slide-modal.modal.fade:not(.show) .modal-dialog.bottom-left {
  -webkit-transform: translate3d(-25%, 25%, 0);
  transform: translate3d(-25%, 25%, 0);
}
.custom-slide-modal.modal.fade:not(.show) .modal-dialog.bottom-right {
  -webkit-transform: translate3d(25%, 25%, 0);
  transform: translate3d(25%, 25%, 0);
}*/

.m-t-10{
  margin-top: 10px;
}
/*.fb-send-to-messenger span,.fb-send-to-messenger iframe{
  width: 150px !important;
  max-width: 100% !important;
}*/
.code-checkbox{
    font-size: 12px !important;
    font-weight: normal !important;
}