@font-face {
  font-family: 'FontAwesome';
  src: url('./icons/font-awesome/fontawesome-webfont.eot?v=4.3.0');
  src: url('./icons/font-awesome/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'), url('./icons/font-awesome/fontawesome-webfont.woff2?v=4.3.0') format('woff2'), url('./icons/font-awesome/fontawesome-webfont.woff?v=4.3.0') format('woff'), url('./icons/font-awesome/fontawesome-webfont.ttf?v=4.3.0') format('truetype'), url('./icons/font-awesome/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.center-block {
    margin-left: auto !important;
    margin-right: auto !important;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.fa-plus {
    content: ""
}
.space15 {
	height: 15px;
}
.mt25 {
	margin-top: 25px;
}
.mb25 {
	margin-bottom: 25px;
}
/*# sourceMappingURL=main.css.map */


body:not(.sidebar-fixed, .topbar-fixed) .sidebar .logopanel {
    background-color: #ffffff;
    padding: 0;
}

body.sidebar-collapsed .sidebar .logopanel {
    width: 70px;
}

.message-types {
	position: relative !important;
	display: inline-block;
	/*width: 540px;*/
	left: 0px !important;
}

#page-builder {
	z-index: 0 !important;
	background: #fff !important;
  top: 0px !important;
  width: 520px;
  margin-left: -10px;
  /*display: flex !important;*/
  /*align-items: flex-start; !important;*/
  /*justify-content: space-between;*/
  /*align-items: center;*/
  /*flex-flow: row wrap;*/

}

#page-builder .form{
	/*margin: 5px 5px !important;*/
  margin-right: 0px;
  /*width: 70px !important;*/
  opacity: 1 !important;
  cursor: pointer;
  float: left;
  text-align: center;
  width: 12.5%;
}

#page-builder .form:hover {
  /*border:1px solid #eee;*/
  background: #eee !important;
}
#page-builder img {
    width: 42px;
    /*width: 60px;
    height: 30px;*/
}
.clear {
	clear:both;
}
.builder-page .main-content {
	background: #F5F5F5 !important;
}
.builder-page .page-content {
	background: #F5F5F5 !important;
	padding: 25px 0px 0 25px !important;
	margin: 0px !important;
}
.placeholder-content {
	min-height: 400px !important;
    padding-bottom: 50px;
}
.placeholder-content-area {
	min-height: 400px !important;
}

.popover {
	min-width: 400px !important;
    z-index: 99 !important;
}
.popover .arrow{
	display: block;
}
.popover input {
	border-left:1px solid #E9E9E9;
	border-right:1px solid #E9E9E9;
	border-top:1px solid #E9E9E9;
}
.popover h5 {
	margin-top: 25px;
}
.popover i {
	left: 0px !important;
}
.addButtonGroup {
	margin-top: 15px;
}
.select2-container--open .select2-dropdown--below {
	/*margin-top: 0px !important;*/
}
.builder-wrapper {
	padding-top: 0px !important;
    width: 500px;
}
.form-delete-btn {

    -webkit-font-smoothing: antialiased;
    color: #D6D6D6;
    content: "\e023";
    font-family: 'Simple-Line-Icons' !important;
    font-size: 20px;
    position: absolute;
    right: 3px;
    margin-top: 35px;
    cursor: pointer;
    width: 25px;
}

.form-delete-btn:hover {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    color: #3B3B3B;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.form-person-btn {

    -webkit-font-smoothing: antialiased;
    color: #D6D6D6;
    content: "\e023";
    font-family: 'Simple-Line-Icons' !important;
    font-size: 20px;
    position: absolute;
    right: 3px;
    margin-top: 65px;
    cursor: pointer;
    width: 25px;
}

.form-person-btn:hover {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    color: #3B3B3B;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.form-add-button-btn {

    -webkit-font-smoothing: antialiased;
    color: #D6D6D6;
    content: "\e023";
    font-family: 'Simple-Line-Icons' !important;
    font-size: 20px;
    position: absolute;
    right: -19px;
    margin-top: 90px;
    cursor: pointer;
    width: 25px;
}

.form-add-button-btn:hover {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    color: #3B3B3B;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.gallery-item .form-person-btn {
    right: -18px !important;
}
.card-item .fileinput .thumbnail {
	margin-bottom: 0px !important;
}

.form-control::-moz-placeholder {
  color: #666;
}
.form-control:-ms-input-placeholder {
  color: #666;
}
.form-control::-webkit-input-placeholder {
  color: #666;
}
.list-group div {
	padding: 0px !important;
}
.list-group {
	position: relative !important;
	margin-bottom: 0px !important;
}
.list-group button {
	margin-bottom: 0px !important;
}

.list-group .elementStep {
  padding: 0px !important;
  margin: 0px !important;
}

.list-group .fileinput-button {
    height: 70px !important;
    min-height: 70px !important;
    padding: 0px !important;
    margin: 0px !important;
}

.list-uploader {
/*	cursor: pointer;
	background: #fff;
	border: 1px solid #666;
	*/
}
.list-upload {
	cursor: pointer;
	background: #fff;
	padding: 10px;
}
.list-img {
	cursor: pointer;
	height: 68px !important;
}

.form-sortable-list-btn {
    -webkit-font-smoothing: antialiased;
    color: #D6D6D6;
    content: "\e023";
    font-family: 'Simple-Line-Icons' !important;
    font-size: 20px;
    position: absolute;
    left: 7;
}

.form-delete-list-btn {

    -webkit-font-smoothing: antialiased;
    color: #D6D6D6;
    content: "\e023";
    font-family: 'Simple-Line-Icons' !important;
    font-size: 20px;
    position: absolute;
    left: 5px;
    margin-top: 28px;
    cursor: pointer;
}

.form-plus-list-btn {
    -webkit-font-smoothing: antialiased;
    color: #D6D6D6;
    content: "\e023";
    font-family: 'Simple-Line-Icons' !important;
    font-size: 20px;
    position: absolute;
    left: 5px;
    margin-top: 55px;
    cursor: pointer;
}

.form-delete-list-btn:hover {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    color: #3B3B3B;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.form-sortable-list-btn:hover {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    color: #3B3B3B;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

.form-plus-list-btn:hover {
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    -webkit-transition: all 250ms ease-in-out;
    color: #3B3B3B;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}
.message-list {
	margin-bottom: 20px;
}

/*.slick-plus:before {
    content: "\2000" !important;
}
*/
/*.slick-next:before { content: "\f004"; }*/
.slick-next i {
    font-size: 20px;
    display: inline-block;
    color: #333333 !important;
}

.blank:before {
    content: none !important;
}

.current-form .form-group {
    margin-bottom: 30px !important;
    cursor: pointer;
}

.current-form .form-group:hover {

}

.current-form .fileinput {
    margin-bottom: 0px !important;
}

.messenger_button {
    margin-bottom: 0px !important;
    margin-top: 0px;
    border: 1px solid rgba(0, 0, 0, .18);
    display: block;
    background: #fff;
    padding: 6px;
    text-align: center;
    border-radius: 3px;
    color: #365899 !important;
    font-weight: bold;
}

.addButtonArea .messenger_button {
  width: 100%;
}

.addedButton {
  color: #365899 !important;
  background-color: #fff !important;
  text-align: center;
  font-weight: bold !important;
}

.addButtonArea {
  /*padding: 0px !important;*/
}

.addButtonArea .btn-group{
    width: 100% !important;
    /*padding: 0px !important;*/
}
.addQuickReplyArea .btn{
    /*padding: 0px !important;*/
    margin-top: 20px;
}

.addQuickReplyArea .btn-group .btn{
    /*padding: 0px !important;*/
    margin-top: 0px !important;
}

.addQuickReplyArea .btn-group {
    margin-top: 20px !important;
}
.widget_slider .addButtonArea, .widget_slider .addQuickReplyArea {
    padding: 0px !important;
}
.gi-big{font-size: 1.5em !important;}
.fileinput-button {
    display: block;
    width: 100%;
    min-height: 130px;
    margin: 0 auto;
    /*opacity: 0.4;*/
}

.image-item .imageupload {
    width: 0px;
}

.video-item .videoupload {
    width: 0px;
}

.file-item .fileupload {
    width: 0px;
}

.audio-item .audioupload {
    width: 0px;
}

/*.fileinput-button:hover {
    opacity: 0.8;
}*/

.imageuploader {
    background: url('/assets/img/add-picture.jpg') no-repeat center center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 400px !important;
    height: 200px !important;
    cursor: pointer;
}

.fileuploader {
    background: url('/assets/img/add-files.png') no-repeat center center;
    background-color:  #404040 !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
}

.audiouploader {
    background: url('/assets/img/add-audio.png') no-repeat center center;
    background-color:  #404040 !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    cursor: pointer;
}


.galleryImageuploader {
    background: url('/assets/img/add-picture.jpg') no-repeat center center;
    /*background-color:  #2e2e2e !important;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.videouploader{
    background: url('/assets/img/add-video.jpg') no-repeat center center;
    background-color:  #404040 !important;
    vertical-align: middle;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: auto 100%;
}
.post_image {
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 200px;
}
.form-error {
    padding: 20px !important;
    display: none;
}
.filename {
    color: #fff !important;
    text-align: center;
    margin-top: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filename h3 {
  text-align: center;
  font-weight: bold !important;
}

.phoneNumber {
    display: none !important;
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.8;
}
.modal-content .modal-body{
    padding-top: 15px !important;
}
.newTag {
    cursor: pointer;
}
.removeAction {
    padding-top: 10px;
    cursor: pointer;
    display: none;
}
/*.removeAction:hover {
    display: block;
    transition all 0.3 ease;
}*/
.preview {
    border: 1px solid #999999;
    /*background: #ffffff;*/
    border-radius: 2px;
    background: #E9E9E9;
    box-shadow: 0 0 0 1px rgba(48,50,51,.1);
    width: 304px;
    margin-top: 68px;
    margin-left: 48px;
    padding: 0px;
    height: 500px;
}
.preview-content {
    background: #fff !important;
    height: 474px !important;
    padding: 10px;
    overflow: auto;
}
.e-p-row-audio {
/*
*/
    padding: 0px !important;
    margin: 10px 0px !important;
}
.preview-content .clearfix {
    position: relative !important;
    /*min-height: 30px;*/
}
.e-p-row {
    width: 85%;
    padding-left: 10px;
    -webkit-box-pack: start;
    margin: 10px auto;
    clear: both;
    min-height: 30px;
}
.e-p-userinfo {
    width: 21.12px;
    height: 21.12px;
    position: absolute;
    bottom: 9px;
    /*display: none;    */
    display: block;
}
.e-p-row .previewButton:first-child {
  border-top-right-radius: 12px !important;
  border-bottom: none;
}
.e-p-row .previewButton {
    border: 1px solid rgba(0, 0, 0, .10);
    color: #365899;
    text-align: center;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 0px 0px 12px 12px;
    font-weight: 500;
    border-bottom: none;
    background: #fff !important;
}

.e-p-row .previewButton:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, .18) !important;
}

.e-p-row .e-p-row-content {
    /*display: inline-table;*/
    margin: 1px 1px 0px 0px;
    line-height: 1.35;
    white-space: pre-line;
    text-align: initial;
    min-height: 30px !important;
    font-family: Arial,sans-serif;
    font-size: 15px !important;
    background: #F1F1F1;
    color: #000;
    font-weight: 400;
    padding: 6px 8px;
    border-radius: 12px;
    max-width: 250px;
    word-wrap: break-word;    
}

.e-p-row .preview-gallery {
  display: block !important;
  padding: 0px !important;
  background: #fff;
}

.e-p-row .preview-gallery .preview-gallery-item-title{
  padding: 6px 6px 0px 6px;
  color: #000 !important;
  font-weight: bold;
  border-collapse: collapse;
  font-size: 12px;
  letter-spacing: inherit;
  line-height: 1.28em;
}
.e-p-row .preview-gallery .preview-gallery-item-subTitle{
  padding: 0px 6px 6px 6px;
  color: #000 !important;
  font-weight: 500;
  border-collapse: collapse;
  font-size: 12px;
  letter-spacing: inherit;
  line-height: 1.28em;
}

.e-p-row-text, .e-p-row-file {
 background: #2F8ACD;
}

.preview-header {
    background: #f7f7f8;
    text-align: center;
    font-size: 11px;
    /*padding: 0 10px;*/
}
.e-p-row .noBottomRadius {
    border-bottom-right-radius: 0px !important; 
    border-bottom-left-radius: 0px !important;
}

.bd-bottom {
    border-bottom: 1px solid #999;
}
.personal-change-item {
    display: flex;
    flex-wrap: wrap;
}

.personal-change-item div{
    width: 50%;
    flex: 1 0 50%;
    padding: 2px !important;
}

.personal-change-item p {
    margin-bottom: 5px !important;
}

.preview-row {
    display: flex;
}
.previvew-nav-content div{
    text-align: center !important;
}
.img-circle {
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.e-p-userinfo:after {
    font-family: icomoon;
    content: "\E91F";
    display: block;
    background-color: #fff;
    width: 8.8px;
    font-size: 6.16px;
    line-height: 8.8px;
    color: #07f;
    height: 8.8px;
    border-radius: 50%;
    border: .88px solid #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}
.e-p-row .e-p-row-img {
    min-width: 200px !important;
    min-height: 100px !important;
    float:left;
}
.e-p-row .e-p-row-card {
    min-width: 200px !important;
    min-height: 100px !important;
    float: left;
}
.preview-gallery {
    width: 93% !important;
}

.slick-item {
    width: 200px !important;
    margin-right: 15px !important;
}

.slick-item .gallery_item_container {
    /* width: 220px; */
    background: #F1F1F1;
    border-radius: 12px;
    color: #000;
    /* padding: 10px; */
}

.slick-item .gallery_item_container .button_area div{

}
.preview-gallery-item-img {
    height: 98px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 12px;
}
.slick-item div:not(.preview-gallery-item-img){
    /*padding: 5px !important;*/
}
.preview-gallery-item-img img {
    width: auto;
    height: 98px;
    display: block;
    margin: 0 auto;
}
.checkbox .checkbox-material .check {
    display: none !important;
}
.preview-list {
    width: 90% !important;
}
.preview-list > div {
    clear: both;
    min-height: 106px !important;
    border:1px solid #999;
    margin-top: 5px;
    padding: 5px;
    word-break: break-all !important;
}
.preview-list img {
    width: 70px !important;
    height: 70px !important;
    border:1px solid #333;
    float:right;
}
.preview-list .col-xs-5 {
    padding-right: 2px !important;
}
.preview-list .list-title {
    font-weight: bold !important;
    padding: 5px;
}
.preview-list .list-subtitle {
    font-weight: normal !important;
    padding: 5px;
}
.list-group .form-sortable-list-btn {
    /*display: none !important;*/
}
.flex {
    display: flex;
    justify-content: flex-start;
}

.between-flex {
    display: flex;
    justify-content: space-between;
}

.previewButton {

}

.listItemButton{
    border: 1px solid #999;
    display: inline-block;
    padding: 5px;
}
.replyButton {
  color:#fff !important;
  border-radius: 12px;
  /*border:1px solid red;*/
  float: right;
  padding: 6px 8px;
  background: #0084ff;
}
.e-p-row.replyArea {
  min-height: 45px !important;
}
.addedQuickReply {
  margin-bottom: 0px !important;
}
.preview-quick-reply {
  margin-top: 20px;
  /*border:1px solid red;*/
}
.replyPreviewButton {
  border-radius: 14px 14px 14px 14px;
  border: 1px solid #999;
  padding: 6px 8px;
  float: left;
  margin: 5px;
}

.ml40 {
  margin-left: 40px;
}
.mt0 {
  margin-top: 0px !important;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.cm-border {
    border-top: 1px solid #efefef !important;
}
.pl34 {
  padding-left: 34px !important;
}
.bbrr12 {
  border-bottom-right-radius: 12px !important;
}
.btrr12 {
  border-top-right-radius: 12px !important;
}
.bblr12 {
  border-bottom-left-radius: 12px !important;
}
.btlr12 {
  border-top-left-radius: 12px !important;
}
.bt0 {
  border-top: 0px;
}
.bb0 {
  border-bottom: 0px;
}

.builder-area {
    /*width: 650px;*/
}
.message-builder {
    float:left;
}
.message-preview {
    font-family: 'Arial', sans-serif!important;
    float: left;
    position: fixed;
    left: 850px;
    top: 150px;
    background-image: url(/assets/img/preview-background.png);
    /* width: 450px; */
    width: 400px;
    height: 740px;
    /*width: 450px;*/
/*    margin-right: 50px;
    margin-left: 100px;*/
}
.card-property-parent {
    padding: 0px !important;
    width: 400px !important;
    margin: 0 auto;
}
.message-builder-area {
    min-width: 1250px;
    padding-left:30px;
    overflow-x: auto;
}

.main-content .page-content.page-builder {
    /*overflow-x: auto;*/
    min-width: 1250px;
}

.pre-g-thumbnail {
    background-size: 70% 120%;
    background-color: #2e2e2e !important;
}
.pre-thumbnail {
/*    background-size: 60% 120%;
    background-color: #2e2e2e !important;*/
}
.clearfix {
    clear: both ;
}

.gallery-item {
    padding: 0 30px;
}
.gallery-item .form-delete-btn, .gallery-item .form-sortable-btn {
    margin-right: -20px;
}
.gallery-item .gallery-card-item .col-sm-12 {
    padding: 0;
}
.gallery-item .gallery-card-item .col-sm-12 span {
    height: 200px !important;
    background-size: 100% auto !important;
}
.list-item .imageuploader {
    width: 100px !important;
    height: 70px !important;
}
.list-preview-img {
    /*border: 1px solid #999;*/
    height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.pl0 {
    padding-left: 0px !important;
}
.modal-open .modal {
    z-index: 1001 !important;
}
.post_content {
    border:1px solid #aaa;
    padding: 10px;
    -webkit-transition: width 2s; /* Safari */
    transition: width 2s;
    cursor: pointer;
}
.post_content:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}
.thumbnail100 {
    width: 100px;
}
.thumbnail75 {
    width: 75px;
}
.thumbnail40 {
    width: 40px;
}
.error-message {
    color : #a94442 !important;
}

.panel .select2-container {
    z-index: 1000 !important;
}

.select2-container {
    z-index: 1002 !important;
}

.bootstrap-datetimepicker-widget {
    z-index: 1001 !important;
}
.modal .select2-container {
    z-index: 1900000 !important;
}
.card .card-description {
    padding: 0px;
}
.card .card-image {
    padding: 0px;
}
.ad-content {
    padding: 20px;
    border: 1px solid #aaa;
}
.adengage-list .card .card-content {
    padding: 5px 20px !important;
}
.col-center-block {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
#sequences_table p{
    text-align: center;
}
.brg {
    border: 1px solid #aaa !important;
}
#modal-sequence-edit {
    z-index: 10000 !important;
}
.sequence_segment {
    border: 1px solid #aaa;
    padding: 15px 0px;
    border-radius: 5px;
    padding: 20px 10px;
}
.sequence_segment label {
    padding-top: 5px;
    white-space: nowrap;
}
.icheckbox {
    width:20px;
    height:20px;
    display:inline-block;

}
.tag {
    cursor: pointer;
}
.bootstrap-tagsinput {
    background-color: #fff !important;
}

.timepicker a.btn {
    width: 40px;
    padding: 0 !important;
}

.timepicker a.btn>span.glyphicon.glyphicon-chevron-down,.timepicker a.btn>span.glyphicon.glyphicon-chevron-up {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
}

.timepicker a.btn>span.glyphicon.glyphicon-chevron-down:before,.timepicker a.btn>span.glyphicon.glyphicon-chevron-up:before {
    display: block;
    text-align: center;
    line-height: 35px;
}

.timepicker .timepicker-hour, .timepicker .timepicker-minute, .picker-switch .glyphicon-calendar, .picker-switch .glyphicon-time {
    text-align: center;
}
.modal-backdrop {
    z-index: 1 !important;
}
.fixed-sidebar .sidebar {
    z-index: 9999 !important;
}
#modal-new-subscribers .select2-container {
    z-index: 900000 !important;
}
.m-t-15 {
    margin-top: 15px !important;
}
.unsubscribe {
    text-decoration: underline !important;
}
.sequence_item {
    cursor: pointer;
}

#event-modal .select2-container {
    z-index: 100 !important;
}
#modal_setting {
    z-index: 2001 !important;
}

.sidebar .sidebar-inner .nav-sidebar li .glyphicon {
    margin-right: 10px;
}

#fanpage-header a{
    display: inline-flex;
    align-items: center;
}

#fanpage-header img{
    width: 45px;
    /*margin-top: 10px;*/
    padding:5px;
}
.header-menu a {
    width: 100%;
}

#fanpage-header .dropdown-menu li{
    border-bottom: 1px solid #ddd;
}

#fanpage-header .dropdown-menu > li > a {
    padding:2px !important;
}
.panel {
    border-radius: 20px !important;
}
.panel-header h3 {
    font-size: 1.4em !important;
}
.logo {
    width: 240px;
    background: url(/assets/img/segmate-new-logo.png) no-repeat center;
    background-size: 80%;
    display: block;
    height: 55px;
}
#modal_setting .col-md-4 .control-label {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description {
  font-family: 'Arial', sans-serif!important;
  line-height: 18px;
  font-size: 12px;
  margin: 5px 0px;
}
.icons-office-52 {
    color: #000 !important;
}
.topbar {
    z-index: 2001 !important;
}
#posts_list .post-area {
    float: left;
}
.fiveLineText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-height: 1.4;
}
#fanpage-header .dropdown-menu {
    height: auto;
    max-height: 500px;
    overflow-x: hidden;
}
.btn-edit {
    background: #bdbdbd !important;
}
.btn-duplicate {
    background: #4527a0 !important;
    color: #fff !important;
}
.btn-share {
    background: #39b6a5bf !important;
    color: #fff !important;
}
.bot-shareable .share-headline{
    min-height: 30px;
}
.bot-shareable .share-headline h2 {
    font-size: 40px;
    line-height: 35px;
    font-weight: 600;
    font-family: 'Roboto', Helvetica, sans-serif
}
.bot-shareable .share-sub-headline{
    min-height: 30px;
}
.bot-shareable .share-sub-headline h4 {
    font-weight: 500;
    line-height: 30px;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 20px
}
.share-header {
    width: 100%;
    display: flex;
    min-height: 100px;
    color : #eeeeee;
    background: #329bd5;
    border-bottom: 2px solid #E9EBEE;
    padding: 0 20px;
}
.bot-shareable .bm-btn.bm-btn-default {
    color: #4995cf !important;
    background-color: #f5f5f5 !important;
    border: 0;
    font-family: Helvetica, sans-serif;
    text-transform: uppercase;
}
.bot-shareable .bm-btn.bm-btn-default:hover{
    transform: translateY(-1px);
    background-color: #efeeee  !important;
    box-shadow: none !important;
    border: none;
}
.bot-shareable .botmap-wrapper {
    width: 100% !important;
}
.share-btn .bot_share {
    bottom: 0;
    right: 0;
    position: absolute;
}
.share-btn {
    text-align: right;
    height: 100%;
    position: relative;
}
.bot-shareable .botmap-canvas {
    border: solid #eceff3;
    width: 100% !important;
}
.bot-shareable .botmap-canvas .canvas-pan {
    padding: 25px 0px 0px 25px !important;
}
.btn-stats {
    background: #a327b0 !important;
    color: #fff !important;    
}
.btn-manage {
    background: #1976d2 !important;
    color: #fff !important;
}
.panel-heading {
    text-align: center;
}
.dashboard-title {
    text-align: center;
    font-weight: bold;
}
.dashboard-desc {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
}

.modal{
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important;
}

.modal-content .modal-header {
    border-radius: 20px 20px 0px 0px !important;
}

.modal-content {
    border-radius: 20px !important;
}
.force-table-responsive {
    overflow-x: auto;
}
.modal-footer {
    padding-right: 20px !important;
    padding-bottom: 10px !important;
}

.select2-container--open .select2-selection--single {
    /*border: 2px solid #000 !important;*/
    border: none !important;
}
.select2-dropdown {
    border: 2px solid #000 !important;
}
.form-control.form-dark {
    color : #333 !important;
}
.popover {
    z-index: 100 !important;
}
[class*="col-"].inside-tooltip input ~ i {
    left: 10px !important;
}

.select2-container--open .select2-dropdown--below {
    /*margin-top: 0px !important;*/
}
.selectAllSubscribers {
    cursor: pointer;
}

.pages-builder .placeholder-container {
    width: 100%;
}

#table-messenger tbody td:nth-child(3) {
    text-align: center !important;
}

.parent_menu_item {
    cursor: pointer;
}
.parent_menu_item i {
    cursor: pointer;
}

.sequence_comment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.sequence_comment div:nth-child(2) {
    width: 80%;
    text-align: center;
}
.select-conversation {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textElement {
    background: #fff;
    min-height: 100px;
    line-height: 20px !important;
    /*padding: 10px;*/
}
.personal-item {
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 10px 5px;
}
.personal-item:hover {
    background: #2F8ACD !important;
}
.form-text .popover-content {
    padding: 0px !important;
}

.card-property {
    background: #fff;
    min-height: 30px;
    /*border:1px solid blue;*/
    padding: 2px 0px !important;
    margin: 2px 0px 0px !important;
}
.dynamic-text .personal-change-item-area:not(.personalization-placeholder) {
    background: #999;
    color: #fff;
    padding: 3px 0px;
    border-radius: 7px;
    cursor: pointer;
}
.intl-tel-input {
    width: 100%;
}
.intl-tel-input .country-list {
    z-index: 1002 !important;
}

.builder-form .popover {
    min-width: 200px !important;
}

.dynamic-text .popover {
    min-width: 400px !important;
}

.button_area div {
  background: #b4b9c3;
  border:1px solid #666;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
}

.conversation_action {
    text-align: left;
}

.main-content .page-content .panel .panel-header h3 {
    text-transform: none !important;
    margin-left: 8px !important;
    margin-top: 8px !important;
}

.main-content .page-content .panel .panel-header .panel-header-icon {
    max-width: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gallery-card-property-parent .btn-group {
    width: 100% !important;
}

#fanpage_table tr {
    cursor: pointer;
}

.imageUploadArea .popover, .videoUploadArea .popover, .fileUploadArea .popover, .audioUploadArea .popover {
    min-width: 500px !important;
}

.imageUploadArea .popover .popover-title, .videoUploadArea .popover .popover-title, .fileUploadArea .popover .popover-title, .audioUploadArea .popover .popover-title{
    margin-top: 0px !important;
}

.gallery-parent {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    overflow-x: auto;
    margin-bottom: 1rem;
}

.gallery-parent li {
    flex: 0 0 25%;
    /*padding: 0.5rem;*/
    width: 100px;
    margin-right: 0.5rem;
    cursor: pointer;
}

.gallery-parent li p {
    text-align: center;
    word-wrap: break-word;
    line-height: 15px;
}



.gallery-parent li img {
    border: 0.5rem solid transparent;
    transition: all 0.25s ease;
}

.gallery-parent li video {
    border: 0.5rem solid transparent;
    transition: all 0.25s ease;
}

.attachment {
    /*width: 100px;*/
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
}
.gallery-parent li.active {
    border: 3px solid #058E9D;
}

.main-content .page-content .panel-content h3 {
  /*margin-top: 0px !important;*/
  /* padding-top: 0px !important; */
}
.remove_conversation {
  color: red;
}

#reImport i, #export i {
    color: #fff;
    font-size: 16px;
}
.m-b-0 {
    margin-bottom: 0px;
}
.dashboard-panel-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.dashboard-panel-title .flex {
  align-items: center;justify-content: center;
}

.upcoming_schedulers_list h3 {
  /* text-align: center; */
}

.sidebar .sidebar-inner .sidebar-footer .pull-left {
    /*padding-top: 0px !important;*/
}
.highlighted {
    font-weight: bold !important;
}
.radius20 {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .static-children > li > a {
      color: #94989D;
      font-family: RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
      font-weight: 300;
}
.sidebar .sidebar-inner .nav-sidebar .nav-parent .static-children {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  visibility: visible;
}


.sidebar .sidebar-inner .nav-sidebar .static-children > li > a {
    color: #94989d;
    display: block;
    font-size: 13px;
    height: 34px;
    line-height: 24px;
    overflow: hidden;
    padding: 7px 20px 7px 49px;
    font-family: "Open Sans", arial;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}


.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .static-children li:before {
    background-color: #D7D7D7;
    border-color: rgba(0, 0, 0, 0);
}

.sidebar .sidebar-inner .nav-sidebar .static-children li:before {
    background: #2b2e33;
    bottom: auto;
    content: "";
    height: 8px;
    left: 23px;
    margin-top: 15px;
    position: absolute;
    right: auto;
    width: 8px;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .nav-parent .static-children {
    background: #f2f2f2;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .static-children:after {
   border-left: 1px solid #D7D7D7;
}


.sidebar .sidebar-inner .nav-sidebar .static-children:after {
    border-left: 1px solid #2b2e33;
    bottom: 0;
    content: "";
    left: 27px;
    position: absolute;
    top: 0;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar > li > a {
    padding: 16px;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar {
    margin-top: 14px;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar > li.active > a .active,
.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .sidebar-menu:hover .active {
    display: block;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar > li.active > a .not-active,
.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .sidebar-menu:hover .not-active {
    display: none;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .sidebar-menu .active {
    display: none;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .sidebar-menu .not-active {
    display: block;
}

.theme-sltd.color-default .nav-sidebar > li.nav-separator {
    text-transform: capitalize;
    padding: 0;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin: 0;
    line-height: 10px;
    margin-top: 10px !important;
    margin-bottom: 10px;
}

.theme-sltd.color-default .nav-sidebar > li.nav-separator:hover {
    cursor: auto;
}

.sidebar .sidebar-inner .sidebar-popover {
    display: none;
    left: 60px;
    top: 4px;
    position: absolute;
    min-width: auto;
    z-index: 9999;
}

.sidebar .sidebar-inner .sidebar-popover .body-wrapper {
    background: #ffffff !important;
    box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding-bottom: 2px;
    margin-left: 21px;
}

.sidebar .sidebar-inner .sidebar-popover .children.settings {
    top: -304px;
}

.sidebar .sidebar-inner .sidebar-popover .left-arrow {
    position: absolute;
    top: 12px;
    left: 13px; 
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff;
    z-index: 9999;
}

.sidebar .sidebar-inner .sidebar-popover .children {
    list-style: none;
    visibility: visible;
}

.sidebar .sidebar-inner .sidebar-popover .children > li > a:hover, 
.sidebar .sidebar-inner .sidebar-popover .children > li > a:active, 
.sidebar .sidebar-inner .sidebar-popover .children > li > a:focus,
.sidebar .sidebar-inner .sidebar-popover .children > li > a.active {
    color: #333;
    text-decoration: none;
}

.sidebar .sidebar-inner .sidebar-popover .children li:last-child .children-title {
    border-bottom: 0;
}
.sidebar .sidebar-inner .sidebar-popover .children .children-title {
    font-size: 14px;
    color: #333;
    background: #ffffff;
    padding: 14px 30px;
    height: auto;
    text-transform: none;
    word-spacing: 1px;
    font-weight: 600;
    word-spacing: 0.3rem;
    border-bottom: 1px solid #efefef;
    line-height: 11px;
    top: 2px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.sidebar .sidebar-inner .sidebar-popover .children > li > a {
    padding: 10px 30px;
    max-width: initial;
    color: #94989d;
    display: block;
    font-size: 13px;
    height: 34px;
    line-height: 24px;
    overflow: hidden;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-weight: 300;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    white-space: nowrap;
    line-height: normal;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar.nav-messenger {
    height: calc(100% - 66px);
    overflow-y: scroll;
    /* hides scrollbar */
    -ms-overflow-style: none;  /*IE 10+*/
    scrollbar-width: none;  /*Firefox*/
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar.nav-messenger::-webkit-scrollbar {
    display: none;  /*Safari and Chrome*/
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar > li.active > a, 
.theme-sltd.color-default .nav-sidebar > li.active > a:hover,
.theme-sltd.color-default .nav-sidebar > li.active > a:hover,
.theme-sltd.color-default .nav-sidebar > li > a.sidebar-menu:hover,
.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.active > a:hover, 
.theme-sltd.color-default .nav-sidebar > li.nav-parent.active > a:active {
    /*background-color: #eff6fb !important;*/
    color: #000 !important;
    /*font-weight: 600;*/
    background-color: #fff !important;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a span:not(.arrow) {
    display: none;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .children > li > a {
    padding: 10px 30px;
    max-width: initial;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .children > li > a.active {
    color: #333 !important;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent.nav-settings .children {
    top: -304px;
    display: none;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent.nav-settings .left-arrow {
    margin-bottom: 3px;
    display: none;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent.nav-settings > a i {
    font-weight: 900;
    font-size: 22px;
    color: #000;
    opacity: 1;
    position: relative;
    left: 3px;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
    background: #ffffff !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    left: 76px;
    top: 4px;
    padding-bottom: 2px;
    min-width: auto;
}

.theme-sltd .sidebar .sidebar-inner .nav-sidebar .nav-parent .children::after,
.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children::after {
    content: none;
}

.theme-sltd .sidebar .sidebar-inner .nav-sidebar .nav-parent .children li::before,
.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children li::before {
    content: none;
}

.theme-sltd:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar .nav-parent .children .children-title {
    display: none;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children .children-title {
    font-size: 14px;
    color: #333;
    background: #ffffff;
    padding: 14px 30px;
    height: auto;
    text-transform: none;
    word-spacing: 1px;
    font-weight: 600;
    word-spacing: 0.3rem;
    border-bottom: 1px solid #efefef;
    line-height: 11px;
    top: 2px;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children li:last-child .children-title {
    border-bottom: 0;
}

.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .left-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff;
    z-index: 9999;
    top: 16px;
    left: 68px;
    position: absolute;
    display: none;
    /* box-shadow: -2px 0px 4px 0px rgb(0 0 0 / 10%); */
}

.sidebar-collapsed .sidebar .sidebar-inner .sidebar-footer {
    display: block !important;
    width: auto;
    margin: 0;
    width: 70px;
    bottom: 5px;
    background: transparent !important;
}

.sidebar .sidebar-inner {
    margin-top: 0 !important;
}

.theme-sltd.color-default .nav-sidebar > li > a.sidebar-menu:hover span {
    opacity: 1;
}

.theme-sltd.color-default.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.active > a, 
.theme-sltd.color-default.sidebar-collapsed .nav-sidebar > li.active > a:hover, 
.theme-sltd.color-default.sidebar-collapsed .nav-sidebar > li.active > a:focus {
    padding: 16px;
}

.theme-sltd.color-default .sidebar .sidebar-inner .nav-sidebar .sidebar-menu img,
.sidebar .sidebar-menu img {
    width: 20px;
    filter: none !important;
}

.theme-sltd.color-default.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .sidebar-menu img {
    width: 28px;
}

.topbar .header-right .header-menu #language-header .dropdown-menu {
    min-width: 240px !important;
    /*max-width: 250px !important;*/
    width: 130px !important;
}

.theme-sltd.color-default .topbar {
    background: #ffffff;
    webkit-box-shadow: 0 4px 11px 0px rgb(0 0 0 / 16%), 0 4px 17px 8px rgb(0 0 0 / 1%);
    -moz-box-shadow: 0 4px 11px 0px rgb(0 0 0 / 16%), 0 4px 17px 8px rgb(0 0 0 / 1%);
    box-shadow: 0 4px 11px 0px rgb(0 0 0 / 16%), 0 4px 17px 8px rgb(0 0 0 / 1%);
}

.blue_menu_button {
    background: #178EDB !important;
}
.blue_menu_button a:hover {
    background: #178EDD !important;
}

/*.topbar .header-right .header-menu .dropdown-menu li {
  background: #178EDB;
}
.topbar .header-right .header-menu .dropdown-menu li:hover {
  background: #178EDD !important;
}

.topbar .header-right .header-menu .dropdown-menu li a:hover {
  background: #44a4e3 !important;
}*/

/*.theme-sltd .topbar .header-right .header-menu #language-header .dropdown-menu a {
  color: #fff !important;
}*/
/*.theme-sltd.color-default .topbar .dropdown-menu > li > a {
  color: #fff !important;
}*/

.topbar .header-right .header-menu #user-header .dropdown-menu li a {
  background: #178EDB;
}
.theme-sltd.color-default .topbar #user-header .dropdown-menu > li > a {
  color: #fff !important;
}
/*.topbar .header-right .header-menu #language-header .dropdown-menu  li a{
    background: #2b2e33;

}
.theme-sltd .topbar .header-right .header-menu #language-header .dropdown-menu a {
    color: #959fa9 !important;
}*/

#accounts_form label {
  /*padding-left: 0px !important;*/
}
.alert-custom {
    background: #E66666 !important;
}
.alert-custom a {
    color: #000 !important;
    /*color: #4E120C!important*/

}
.alert-custom a:hover {
    color: #333 !important;
}


/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  z-index: 2000 !important;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  z-index: 2000 !important;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.required {
    position: relative;
}
.required label:before {
    content:" *";
    color: #e32;
    position: absolute;
    left: -8px;
    top: -4px;
}
#chat_plugin_form .form-group {
    margin: 0px !important;
}

.elementNavigation {
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
}
.elementNavigation .elementStep {
  color: #0097a7;
}
.elementNavigation .navigation {
  padding-left: 10px;
}

.elementNavigation .navigation:last-child {
  /*color: #cd6a6a !important;*/
}

.elementNavigation .navigation:last-child a{
  color: #cd6a6a !important;
}

.dib {
  display: inline-block !important;
}

.quickReplyArea {
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  pointer-events: auto;
  flex-wrap: wrap;
}

.quickReplyArea .addedQuickReply {
  /* max-width: 25%; */
  margin: 5px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  border-radius: 1.3em;
  color: #0084ff !important;
  border: 1px solid rgba(0, 0, 0, .20);
  padding-left: 8px;
  padding-right: 8px;
}
.quickReplyArea .btn-group {
  padding: 0px;
  display: flex;
  margin-bottom: 5px;
  margin-right: 5px;
}
.quickReplyArea .btn-group .addedQuickReply {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
}

.quickReplyArea .btn-group .elementStep {
  padding: 7px;
  border-top-right-radius: 1.3em;
  border-bottom-right-radius: 1.3em;
}
.quickReplyArea .btn-group .elementStep i {
  padding-right: 0px !important;
}

.bot-actions {
  margin-top: -30px;
  /*margin-left: 90px;  */
  text-align: center;
}

.reminder {
    background: #c4dfef;
    clear: both;
    padding: 10px;
    color: #000;
    border-radius: 10px;
}
.userInputButton {
    text-align: center;
    width: 100%;
}
.actionArea {
    /*margin-top: -100px;*/
}

#reply_limit_parent:after {
    content: "Days";
    display: inline-block;
    /* display: block; */
    position: absolute;
    /* float: right; */
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}

#reply_limit_parent {
    position: relative;
}
#reply_limit_days {
    text-align: center;
}
.page-builder-image img {
    width: 51px;
    height: 57px;
}
.subscribers .dataTables_filter, .customfieldsReport .dataTables_filter {
    display: none;
}

.segments_subscribers .dataTables_filter {
    display: none;
}

.form-userInput .form-sortable-btn, .form-userInput .form-delete-btn, .form-userInput .form-person-btn {
    left: 10px !important;
}

.popover-stats {
    min-width: 300px !important;
}

.quick-item {
    border: 1px solid #999;
    width: 33.333%;
    margin: 1%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.quick-item .btn-group {
    width: 100%;
    display: block !important;
}

.quick-item .btn-group .btn {
    width: 50%;
}

.quick-item .btn-group a {
    margin-left: -1px !important;
}

.quick-item img {
    margin: 0 auto;
}

.quick-item h4 {
    text-align: center;
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
    font-weight: bold;
}


.quick-row {
    display: flex; align-items: center;justify-content: left;
}

.sidebar-menu {
    display: flex !important;
    align-items: center !important;
}
.sidebar-menu span {
    margin-left: 10px;
}
.inline {
    display: flex;
    justify-content: left;
    align-items: center;
}

.inline h3{
    margin-left: 10px !important;
}
.img-logo {
/*    width: 25px;
    height: 25px;*/
}

.nav-sidebar .children {
    z-index: 1005 !important;
}

#login-home {
  /*background: url('/assets/img/login-background.png') no-repeat center center fixed; */
  /*-webkit-background-size: cover;*/
  /*-moz-background-size: cover;*/
  /*-o-background-size: cover;*/
  /*background-size: cover;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
}

#login-home .panel {
    box-shadow: none;
    margin: 0;
}

#login-home .panel .panel-body {
    padding: 0;
}

#login-home .right-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

#login-home .login-wrapper {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

#login-home .login-wrapper .login-logo {
    margin-bottom: 50px;
}

#login-home .login-wrapper h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
}

#login-home .alert {
    margin-top: 20px;
}

#login-home textarea:hover, 
#login-home input:hover, 
#login-home textarea:active, 
#login-home input:active, 
#login-home textarea:focus, 
#login-home input:focus, 
#login-home button:focus, 
#login-home button:active, 
#login-home button:hover, 
#login-home label:focus, 
#login-home select:hover, 
#login-home select:focus, 
#login-home .btn:active, 
#login-home .btn.active {
    outline: none !important;
    -webkit-appearance: auto;
}

#login-home button:focus, 
#login-home button:active, 
#login-home button:hover {
    -webkit-appearance: button; 
    outline: 0 !important;
}

#login-home .login-wrapper form {
    padding: 15px;
}

#login-home .login-wrapper .form-group {
    margin-bottom: 15px;
}

#login-home .login-wrapper .control-label {
    margin-bottom: 6px !important;
}

#login-home .login-wrapper .form-control {
    border-radius: 2px;
    padding: 8px 14px;
    color: #333;
    font-weight: 400;
    font-size: 16px;
    height: 40px;
    background: #F1f1f1 !important;
    border: 1px solid #e6e6e6;
}

#login-home .login-wrapper .login-subimt,
#login-home .login-wrapper .form-group button[type=submit] {
    width: 100%;
    text-transform: none;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    background-color: #12c100 !important;
    margin-right: 0;
    margin-left: 0;
    color: #ffffff;
    letter-spacing: 1px;
    -webkit-appearance: button; 
    outline: 0 !important;
}

#login-home .login-wrapper .login-acc .checkbox {
    padding-top: 0;
}

#login-home .login-wrapper .login-acc.signup-area p {
    display: inline;
}

#login-home .login-wrapper .login-acc.signup-area a {
    font-size: 12px;
}

#login-home .left-panel {
    min-width: 100%;
    min-height: 100vh;
    background-color: rgb(241, 241, 241);
    background-color: rgb(248, 249, 251);
}

#login-home .left-panel h2 {
    margin-top: 0;
    padding: 100px 50px 50px 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: center;
    line-height: 32px;
    font-size: 1.2em;
}

#login-home .left-panel img {
    margin:  0 auto;
}

.no-radius {
    border-radius: 0px !important;
}

#login-form {
    padding: 15px;
}


#login-form .form-control{
    background: #e6e6e6 !important;
}

#login-home .control-label {
    color: #828282 !important;
    font-size: 12px !important;
    margin-bottom: 0px !important;
}

#login-form .form-group {
    margin-top: 5px;
    margin-bottom: 0px;
}

.login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-logo img {
    margin-top: 25px;
    widows: 120px;
    height: 85px;
}

.login-acc {
    display: flex;
    justify-content: space-between !important;
}

.login-acc label {
    font-size: 12px !important;
}

.login-acc:before, .login-acc:after {
    display: none !important;
}

.login-subimt {
    /*background: #86cddd !important;*/
    color: #fff !important;
}

.signup-area {
    border-top: 1px solid #D7D7D7;
    padding: 20px 0px;
}

.signup-area p, .signup-area .btn {
    font-size: 12px;
    font-weight: 500;
}
.signup {
    border: 1px solid #d7d7d7 !important;
    background: #ffffff !important;
}
.non_sub_area h3 {
    margin-bottom: 5px !important;
}
.cm-label {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-right: 10px;
}

.cm-plan .price{
    text-align: center;
}

.cm-plan .plan {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm-plan h5 {
    margin: 5px;
    text-align: center;
}

.cm-plan .panel-header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;    
}

.m0 {
    margin: 0px !important;
}

.cm_plan_area {
    padding: 0px !important;
    /*border: 1px solid #309BD5 !important;*/
}

.cm_plan_area .price {
    color: #06aa58 !important;
}

.description_important {
    font-weight: bold;
}

.subscription_message {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.subscription_message p:first-child {
    margin-bottom: 0px;
    margin-right: 5px;    
}

.subscription_message p:nth-child(2) {
    margin-bottom: 0px;
    margin-right: 5px;    
    text-transform: uppercase;
}

#action-area .dropdown-menu {
    z-index: 1002 !important;
}

.action-content {
    margin-top: 15px;
}

.action-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.action-delete {
    margin-left: 15px;
}

.action-delete i {
    cursor: pointer;
}
.action-plugins {

}

#navigation-switcher .btn {
    width: 100%;
    margin-top: 10px;
    background-color: #fff !important;
}

#header-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navigation-switcher a {
    color: #000;
}
.sidebar .sidebar-inner .nav-sidebar li > a .fa.arrow {
    margin-top: 0px;
}

.adengage-type {
    margin-bottom: 20px;
    margin-top: 10px;
}

.adengage-type .select2 {
    width: 100% !important;
}

.ad-engage-name-wrapper .ad-engage-icon,
.ad-engage-name-wrapper .ad-engage-image {
    border: 2px solid #329bd5;
    border-radius: 4px;
    color: #329bd5;
    padding: 4px 2px;
    width: 40px;
    display: inline-block;
}

.ad-engage-name-wrapper .ad-engage-image {
    padding: 1px 2px;
}

.ad-engage-name-wrapper .ad-engage-icon i {
    font-size: 20px;
    position: relative;
    right: -6px;
}

.ad-engage-name-wrapper .ad-engage-image img {
    width: 32px;
    right: 1px;
    position: relative;
}

.sleek-modal.modal {
  background: rgba(44, 50, 68, 0.5);
  transition: opacity 0.5s;
  border-radius: 0 !important;
}

.sleek-modal.modal .modal-dialog .modal-content {
  border-radius: 8px !important;
  background: #f9f9f9;
}

.sleek-modal .modal-content .modal-header {
  padding: 18px 24px;
  border-bottom: 1px solid #838383;
}

.sleek-modal .modal-content .modal-header .modal-title {
  font-size: 18px;
  font-weight: 500;
}

.sleek-modal.modal .modal-content .modal-header .close {
  position: absolute;
  right: -16px;
  box-shadow: 0 0px 0px 4px #888B92;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
  padding: 0px 6px 2px 6px;
  color: #888B92;
  font-weight: 800;
  font-size: 26px;
  top: 20px;
}

.sleek-modal.modal .modal-content .modal-header .close:hover {
  color: #414141;
}

.sleek-modal.modal .modal-content .modal-body {
  max-height: calc(100vh - 206px);
  overflow-y: auto;
}

.sleek-modal.modal .modal-content .modal-footer {
  padding: 18px 24px;
}

.sleek-modal input[type=radio] {
  position: relative;
  top: -1px;
}

.sleek-modal .message-type .form-label {
  margin-bottom: 12px;
  font-family: 'Roboto', Helvetica, sans-serif;
  text-transform: none;
}

.sleek-modal .message-type label {
  font-weight: 400;
  font-family: Lato, Helvetica, sans-serif !important;
}

.sleek-modal .message-type-body {
  padding: 0 28px;
  margin-bottom: 18px;
}

.sleek-modal .message-type-body a {
  font-size: 14px;
  font-family: Lato, Helvetica, sans-serif !important;
}

.sleek-modal .message-type-body p {
  font-size: 14px;
  line-height: 22px;
  display: inline;
}
.sleek-modal .form-control, .sleek-modal .form-control:focus {
  border: 1px solid #E3E8EF;
  padding: 0 8px;
  border-radius: 4px;
  background: none;
}

.sleek-modal .form-control:focus
{
  border-color: #388DFC;
  background-image: unset !important;
  background: none;
}

.sleek-modal .select2-selection__choice {
  background: #007FFF !important;
  border-radius: 4px !important;
}

.sleek-modal .select2-selection__choice__remove:hover {
  color: #F5F5F6 !important;
}

.sleek-modal a {
  color: #007FFF;
  font-weight: 600;
}

.sleek-modal .select2-container--default .select2-selection--multiple{
  background: #f9f9f9 !important;
}

.sleek-modal .select2-container.select2-container--focus{
  border: 1px solid #388DFC;
}

.sleek-modal .text-muted {
  color: #888B92;
}
.sleek-modal .form-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  font-family: Lato, Helvetica, sans-serif !important;
  font-size: 16px;
}
.sleek-modal .bm-form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.sleek-modal .bm-form-switch i {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
  background-color: #c7ccd4;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.sleek-modal .bm-form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.sleek-modal .bm-form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 4px #c7ccd4;
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.sleek-modal .bm-form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.sleek-modal .bm-form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }

.sleek-modal .bm-form-switch input { display: none; }

.sleek-modal .bm-form-switch input:checked + i { background-color: #007FFF; }

.sleek-modal .bm-form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }

.sleek-modal .bm-form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }
/* End Switch Input */

.sleek-modal .modal-dialog {
  margin-top: 0%;
  padding: 100px 0px 100px 0px;
}
.sleek-modal-group-dropdown.select2-dropdown--below {
  top: 32px;
}

.sleek-modal .modal-title {
    text-transform: none;
}

.sleek-modal .modal-header .modal-title {
    text-transform: none;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.sleek-modal .modal-header .modal-description {
    text-transform: none;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 22px;
    display: inline;
    font-family: Lato, Helvetica, sans-serif !important;
}

.sleek-modal .text-muted {
  color: #888B92;
}

.sleek-modal.modal .modal-content .modal-body {
  max-height: none;
}

.sleek-modal .modal-content label {
  font-family: Lato, Helvetica, sans-serif;
}

.sleek-modal .modal-content .form-group {
  margin-bottom: 20px;
}

.sleek-modal .modal-content .form-control {
  font-size: 14px;
  height: 40px;
  border: 1px solid #d3d3d3;
}

.sleek-modal .modal-content .form-control:focus {
  border-color: #388DFC;
}

.sleek-modal .modal-content .select2-container {
  width: 100% !important;
}

.sleek-modal .modal-content .select2-container--default .select2-selection--single {
  height: 40px;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3;
  background: transparent;
}

.sleek-modal .modal-content .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
  font-size: 14px;
  color: #555;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif !important;
}

.sleek-modal .modal-content .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}

.sleek-modal .modal-content .modal-footer {
  padding-bottom: 18px !important;
}

.sleek-modal .modal-content .modal-footer .btn-link {
  background: none !important;
  box-shadow: none;
  text-transform: none;
  color: #616161;
  /* font-family: 'LATO'; */
  /* letter-spacing: 1px; */
  /* font-size: 16px; */
}

#deleteIntegrationModal .modal-body h4 {
    text-transform: none;
    font-family: 'Roboto', Helvetica, sans-serif;
    font-weight: 400;
    margin-bottom: 4px;
}

#deleteIntegrationModal .modal-body p {
    text-transform: none;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 22px;
    display: inline;
    font-family: Lato, Helvetica, sans-serif !important;
}

/*
    Beamer Changelog icon in the header
 */
.beamer-button:hover {
    color: #333;
    animation: beamer-ring 4s .7s ease-in-out infinite;
}

.beamer-button {
    color: #333;
}

.beamer-button img {
    height: 30px;
    margin-top: -4px;
}

@keyframes beamer-ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}
/*
    End Beamer
 */

/* Toast Notification */

.toastify,
.toastify.toastify-right,
.toastify.toastify-top,
.toastify.toastify-bottom,
.toastify.toastify-left {
    font-family: sans-serif !important;
    border-radius: 4px;
    font-weight: 400;
    color: #F5F5F6;
    padding: 14px 30px;
    max-width: 320px;
}

.toastify.toast-default {
    background: #414141;
}

.toastify.toastify-right {
    right: 10px;
}

.toastify.toastify-top {
    margin-top: 70px;
}

.toastify.toast-info {
    border: 1px solid #007FFF;
    background: #007FFF;
}

.toastify.toast-warning {
    border: 1px solid #FC9F5B;
    background: #FC9F5B;
}

.toastify.toast-error {
    border: 1px solid #F37272;
    background: #F37272;
}

.toastify.toast-success {
    border: 1px solid #007FFF;
    background: #007FFF;
}

.sg-primary-btn {
    background-color: #12c100 !important;
    color: #fff !important;
}
.sg-primary-btn:hover{
    background-color: #14CC00  !important;
    color: #fff !important;
}
.sg-secondary-btn {
    background: #1976d2 !important;
    color: #fff !important;
}
.sg-panel-heading {
    background: #1976d2 !important;
    color: rgba(255, 255, 255, 0.84);
    border: 0;
    text-align: center;
    padding: 10px 15px;
}
/* End Toast Notification */